import React from "react";
import Certifs from "./CardCertifs";
import '../../styles/styles.scss';
import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from '../../components/GlobalStyles/StylesReusable';
import { useDarkMode } from '../../components/DarkMode';



export default function Certificats() {
	const [theme, toggleTheme] = useDarkMode();
	const themeMode = theme === "light" ? lightTheme : darkTheme;

    return (
        <>
            <Certifs theme={theme}  toggleTheme={toggleTheme}/>
        </>
    );
}

