
import React, { useState, useEffect } from "react";

export const useDarkMode = () => {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      // SET THEME IN LOCALSTORAGE
      window.localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      // SET THEME IN LOCALSTORAGE
      window.localStorage.setItem("theme", "light");
    }
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    setTheme(localTheme || "dark");
  }, []);

  return [theme, toggleTheme];
};
