import React from "react";
import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from './components/GlobalStyles/StylesReusable';
import { GlobalStyles } from './components/GlobalStyles/StylesReusable';
import { useDarkMode } from './components/DarkMode';
import Home from './views/Home/index';
import NavBar from "./components/NavBar";
import GitHub from "./views/GitHub/index";
import Certificats from "./views/Certificats/index";
import Contact from "./views/Contact/index";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";


export default function App() {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyles />
      <NavBar key="navbar" theme={themeMode}  toggleTheme={toggleTheme} />
      <Routes>
        <Route element={
          [
            <Home key="home" theme={themeMode}  toggleTheme={toggleTheme} />,
          ]
        } 
          path="/" 
        />
        <Route element={
          <GitHub key="github" theme={themeMode}  toggleTheme={toggleTheme} />} 
          path="/profile/github" 
        />
        <Route element={
          <Certificats key="certifications" theme={themeMode}  toggleTheme={toggleTheme} />} 
          path="/certifications" 
        />
        <Route element={
          <Contact key="github" theme={themeMode}  toggleTheme={toggleTheme} />} 
          path="/contact" 
        />
      </Routes>
    </ThemeProvider>
  );
}
