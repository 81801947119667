import React, { useEffect, createRef } from "react";
import styled from "styled-components";
import { List, Container } from "reactstrap";
import '../../styles/styles.scss';
import Stack from "./Stack";
import Footer from "../../components/Footer";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AntoineImage from '../../static/antoine.jpg';

export default function Banner({ theme, toggleTheme }) {
    const ref = createRef(null);


    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://platform.linkedin.com/badges/js/profile.js';
        script.async = true;
        script.defer = true;
        
        script.onload = () => {
            // The script has successfully loaded, you can force a re-render here
            // to ensure that the badge is displayed.
        };
    
        document.body.appendChild(script);
    
        return () => {
            document.body.removeChild(script);
        };
    }, []);
  

    return (
        <>
            <Home>
                <ContainerHome>
                    <CardStyled style={{height: '100%'}}>
                        <CardActionArea>
                            <Avatar style={{margin: '1em', height: '4em', width: '4em'}} alt="Antoine Heidet" src={AntoineImage} />
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                <strong>Antoine Heidet</strong>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Développeur FullStack 
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                FullStack ReactJS/ Django Rest Framework
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{marginBottom: '1em'}}>
                            <Button href="https://www.linkedin.com/in/antoine-heidet/" target="_blank" variant="contained" size="small" color="primary">
                                Voir le profil
                            </Button>
                        </CardActions>
                    </CardStyled>
                    {/* <div className="badge-base LI-profile-badge" ref={ref} data-locale="fr_FR" data-size="medium" data-theme="light" data-type="VERTICAL" data-vanity="antoine-heidet" data-version="v1"></div> */}
                    <List type="inline">
                        <li style={{ color: theme.text }}>
                            Passionné par ce secteur d’activité, je suis curieux et j'aime expérimenter, <br />
                            découvrir et apprendre au fur et à mesure de mes projets professionnels et personnels. 
                        </li>
                        <li style={{ color: theme.text }}>
                            ReactJS / Django && Django Rest API / Docker 💕❤️💕❤️💕
                        </li>
                        <br />
                        <li style={{ color: theme.text }}>
                            Stack :
                            <ul>
                                <li style={{ color: theme.text }}>
                                    Développement Front-End : JavaScript / Ext JS / ReactJS / (NPM, Yarn, Redux, Hooks, ReactDOM, ReactStrap, MaterialUI, ApexChart)
                                </li>
                                <li>
                                    Développement Back-End : Python / Django REST API (Oauth JWT, MVT, ORM, CRUD), WebSocket Redis
                                </li>
                                <li>
                                    Architechture: MVC / MVT 
                                </li>
                                <li>
                                    OS : Windows, MacOS, Linux (Debian, Unbuntu, Kali, RedHat)
                                </li>
                                <li>
                                    Infrastructure et intégration continue : Docker et Docker-Compose, Ansible, Apache, Nginx, VPS, Saas, Paas
                                </li>
                                <li>
                                    Méthodologie: Agile, DevOps 💕❤️💕❤️💕
                                </li>
                            </ul>
                        </li>
                        <li style={{paddingTop: '1em'}}>
                            Centre d'intérêt: Développement / Blockchain / Cryptographie / Crypto-actif / NFT / Système décentralisé / Musique ♫♫♫
                        </li>
                    </List>
                </ContainerHome>
                <Stack theme={theme}  toggleTheme={toggleTheme}/>

                <Footer/>
            </Home>
        </>
    );
}


const CardStyled = styled(Card)`
    @media screen and (max-width: 768px) {
       height: initial!important;
       margin : 3em;
    }
`
const Home = styled.div`
    height: 90%;
    overflow: scroll;
    background-color: ${({ theme }) => theme.bg};
    color : ${({ theme }) => theme.text};
`

const ContainerHome = styled(Container)`
    --bs-gutter-x: 0em;
    font-family: Andale Mono,monospace;    
    background-color: ${({ theme }) => theme.bg};
    display: flex;
    padding-top: 5em;
    justify-content: center;
    color : ${({ theme }) => theme.text};
    ul {
        padding: 2em;
        color : ${({ theme }) => theme.text};
    }

    @media screen and (max-width: 768px) {
        display: initial;
        .badge-base {
            text-align: center;
            text-align: -webkit-center;
        }
	}
`


