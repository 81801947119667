import '../../styles/styles.scss';
import React from "react";
import UserProfile from "./UserProfile";
import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from '../../components/GlobalStyles/StylesReusable';
import { useDarkMode } from '../../components/DarkMode';



export default function GitHub() {
	const [theme, toggleTheme] = useDarkMode();
	const themeMode = theme === "light" ? lightTheme : darkTheme;
    
    return (
      <>
        <UserProfile theme={theme}  toggleTheme={toggleTheme}/>
      </>
  );
}

