import React from "react";
import styled from "styled-components";
import { Name } from "./Contact";
import { darkTheme, lightTheme } from '../../components/GlobalStyles/StylesReusable';
import { useDarkMode } from '../../components/DarkMode';
import { ThemeProvider } from 'styled-components';


export default function Contact() {
    const [theme, toggleTheme] = useDarkMode();
	const themeMode = theme === "light" ? lightTheme : darkTheme;

    return (
    <>
        <ThemeProvider theme={themeMode}>
            <ContactForm id="form-app">
                <Name />
            </ContactForm>
        </ThemeProvider>
    </>
  );
}

const ContactForm = styled.div`
    min-width: 280px;
    max-width: 400px;
    padding: 1rem 2rem 5rem;
    background-color: ${({ theme }) => theme.bg};
    border-radius: 8px;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.3);
    margin: 50px auto;
    color: ${({ theme }) => theme.text};
    font-family: 'Nunito', sans-serif;
    font-size: 0.8rem;
    h1 {
        margin-bottom: 3rem;
    }
    
    .form-container {
        margin: 0 2rem;
        text-align: left;
    }
    
    .user-info {
    display: inline-block;
    margin-top: 0.8rem;
    margin-left: 0.6rem;
    font-size: 0.8rem;
    }
    
    .user-info:first-of-type {
    margin-top: 0;
    margin-right: 2px;
    }
    
    .MuiInputBase-root {
        display: flex;
        padding: 0.4rem 0.6rem;
        margin: 0.8rem 0;
        border-radius: 5px;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
        text-align: left;
        background-color: #fff;
    }

    .form-input span {
        padding-top: 2px;
        padding-bottom: 2px;
    }    
`
