import React from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styled from "styled-components";
import axios from "axios";

const Header = () => {
    return (
      <h1>Contact</h1>
    );
};

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clearScreen: false,
      messageSubmitted: false,
      resetForm: false,
      name: this.props.name,
      email: this.props.email,
      message: "",
      errorMessage: "",
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  validate() {
    return (
      this.state.message.length > 0
    );
  }

  handleBlur(event) {
    if (!this.validate()) {
      this.setState({errorMessage: "Message Requis!"});
    } else {
      this.setState({errorMessage: ""});
    }
  }
  
  handleChange(event) {
    this.setState({message: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.validate()) {
      this.setState({ errorMessage: "Message requis!" });
      return;
    }
  
    const { name, email, message } = this.state;
  
    axios
      .post(
        "https://api.sendgrid.com/v3/mail/send",
        {
          personalizations: [
            {
              to: [{ email: "destinataire@gmail.com" }],
              subject: "Nouveau message de contact",
              dynamic_template_data: {
                name: name,
                message: message,
              },
            },
          ],
          from: { email: "votre-adresse-email@gmail.com" },
          template_id: "VOTRE_TEMPLATE_ID",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer VOTRE_CLE_API_SENDGRID`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        this.setState({ clearScreen: true, messageSubmitted: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          errorMessage: "Une erreur est survenue lors de l'envoi de l'e-mail.",
        });
      });
  }

  handleReset() {
    this.setState({clearScreen: true, resetForm: true});
  }

  render() {
    if (!this.state.clearScreen) {
      return (
        <div>
          <Header />
          <div className="form-container">
            <div className="user-info">
              <span className="ion-ios-person"></span>
              <Person >&nbsp;&nbsp;{this.state.name}</Person>
            </div>
            <br />
            <div className="user-info">
              <span className="ion-ios-mail"></span>
              <Mail>&nbsp;&nbsp;{this.state.email}</Mail>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-input">
                  <span className="ion-md-create"></span>
                <textarea value={this.state.value} placeholder="Ecrire votre message" rows="8" autoFocus onBlur={this.handleBlur} onChange={this.handleChange} />
              </div>
              <span className="error-message">{this.state.errorMessage}</span>
              <Button type="button" value="Effacer" variant="contained" color="error" onClick={this.handleReset} >Effacer</Button>

              {!this.validate() ? 
                <></> 
              : 
                <Button type="submit" value="Envoyer" variant="contained" color="success">Envoyer</Button>
              }
            </form>
          </div>
        </div>
      );
    }
    if (this.state.messageSubmitted) {
      return (
        <Submitconfirm name={this.state.name} email={this.state.email} message={this.state.message} />
      );
    }
    if (this.state.resetForm) {
      return (
        <Name />
      );
    }
  }
}
  
class Email extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clearScreen: false,
      emailSubmitted: false,
      resetForm: false,
      name: this.props.name,
      email: "",
      errorMessage: "",
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  validate() {
    return (
      /^[a-zA-Z0-9\.]+@[a-zA-Z0-9\.]+\.[A-Za-z]+$/.test(this.state.email)
    );
  }

  handleBlur(event) {
    if (!this.validate()) {
      this.setState({errorMessage: "Adresse mail requis!"})
    } else {
      this.setState({errorMessage: ""});
    }
  }
  
  handleChange(event) {
    this.setState({email: event.target.value});
  }

  handleSubmit(event) {
    if (!this.validate()) {
      event.preventDefault();
      return;
    } else {
      event.preventDefault();
      this.setState({clearScreen: true, emailSubmitted: true});
    }
  }

  handleReset() {
    this.setState({clearScreen: true, resetForm: true});
  }

  render() {
    if (!this.state.clearScreen) {
      return (
        <div>
          <Header />
          <div className="form-container">
            <div className="user-info">
              <span className="ion-ios-person"></span>
              <Person>&nbsp;&nbsp;{this.state.name}</Person>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-input">
                  <span className="ion-ios-mail"></span>
                <TextField type="email" value={this.state.value} placeholder="Entrez votre adresse mail" autofocus="true" onBlur={this.handleBlur} onChange={this.handleChange} />
              </div>
              <span className="error-message">{this.state.errorMessage}</span>
              {!this.validate() ? 
                <></> 
              : 
                <Button type="submit" value="Suivant" variant="contained" >Suivant</Button>
              }
              <Button onClick={this.handleReset} type="submit" value="Effacer" variant="contained" color="error">Effacer</Button>
            </form>
          </div>
        </div>
      );
    }
    if (this.state.emailSubmitted) {
      return (
        <Message name={this.state.name} email={this.state.email} />
      );
    }
    if (this.state.resetForm) {
      return (
        <Name />
      );
    }
  }
}
  
export class Name extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayHeader: true,
      clearScreen: false,
      nameSubmitted: false,
      name: "",
      errorMessage: "",
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validate() {
    return (
      this.state.name.length > 0
    );
  }

  handleBlur(event) {
    if (!this.validate()) {
      this.setState({errorMessage: "Nom requis!"})
    } else {
      this.setState({errorMessage: ""});
    }
  }
  
  handleChange(event) {
    this.setState({name: event.target.value})
  }
  
  handleSubmit(event) {
    if (!this.validate()) {
      event.preventDefault();
      return;
    } else {
      event.preventDefault();
      this.setState({clearScreen: true, nameSubmitted: true});
    }
  }

  render() {
    if (!this.state.clearScreen) {
      return (
          <div>
            <Header />
      
            <form onSubmit={this.handleSubmit}>
              <div className="form-input">
                <span className="ion-ios-person"></span>
                <TextField variant="outlined" type="text" value={this.state.value} placeholder="Votre nom" onBlur={this.handleBlur} onChange={this.handleChange} />
              </div>
              <span className="error-message">{this.state.errorMessage}</span>
              {!this.validate() ? 
                <></> 
              : 
                <Button type="submit" value="Suivant" variant="contained" >Suivant</Button>
              }
            </form>
          </div>

      );
    }
    if (this.state.nameSubmitted) {
      return (
        <Email name={this.state.name} />
      );
    }
  }
}
  
class Submitconfirm extends React.Component {
    render () {
      return (
        <div>
           <Header />
          <p className="submit-message">Merci pour votre message !</p>
          <p>Votre e-mail a été soumis et nous vous contacterons sous peu..</p>
          <br />
          <h3>Votre Message:</h3>
          <br />
          <div className="user-info">
            <span className="ion-ios-person"></span>
            Nom: <Person>&nbsp;&nbsp;{this.props.name}</Person>
          </div>
          <br/>
          <div className="user-info">
            <span className="ion-ios-mail"></span>
            Mail: <Mail>&nbsp;&nbsp;{this.props.email}</Mail>
          </div>
          <br />
          <div className="user-info">
            <span className="ion-ios-create"></span>
            Message: <MessageRender>&nbsp;&nbsp;{this.props.message}</MessageRender>
          </div>
        </div>
      );
    }
}


const Person = styled.span`
  font-size: 20px;
`;

const Mail = styled.span`
  font-size: 20px;
`;

const MessageRender = styled.small`
  font-size: 20px;
`;
