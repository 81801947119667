import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { GiHamburgerMenu } from "react-icons/gi";
import { VscChromeClose } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";

export default function NavBar({ theme, toggleTheme }) {
  const [navbarState, setNavbarState] = useState(false);
  const navigate = useNavigate();
  const navRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setNavbarState(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Nav>
        <ToggleTheme id="toggle" onClick={toggleTheme}>
          <div className="toggle-inner" />
        </ToggleTheme>
        <div className="brand1">
          <a href="/">
            <p className="social-name">Heidet Antoine</p>
          </a>
          <div className="toggle">
            {navbarState ? (
              <VscChromeClose onClick={() => setNavbarState(false)} />
            ) : (
              <GiHamburgerMenu
                className="hamburger-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  setNavbarState(true);
                }}
              />
            )}
          </div>
        </div>
        <ul className="links">
          <li>
            <a onClick={() => navigate(`/`)}>Accueil</a>
          </li>
          <li>
            <a onClick={() => navigate(`/profile/github`)}>GitHub</a>
          </li>
          <li>
            <a onClick={() => navigate(`/certifications`)}>Certifications</a>
          </li>
          <li className="litest">
            <a onClick={() => navigate(`/contact`)}>Contact</a>
          </li>
        </ul>
      </Nav>
      <ResponsiveNav
        state={navbarState}
        className={navbarState ? "visible" : ""}
        ref={navRef}
      >
        <ul>
          <li>
            <a href="#home" className="active" onClick={() => navigate(`/`)}>
              Accueil
            </a>
          </li>
          <li>
            <a onClick={() => navigate(`/profile/github`)}>
              GitHub
            </a>
          </li>
          <li>
            <a onClick={() => navigate(`/certifications`)}>
              Certifications
            </a>
          </li>
          <li>
            <a onClick={() => navigate(`/contact`)}>Contact</a>
          </li>
        </ul>
      </ResponsiveNav>
    </>
  );
}

const ToggleTheme = styled.div`
  width: 50px;
  display: flex;
  padding: 5px;
  background-color: #1a202c;
  display: block;
  border-radius: 1000px;
  cursor: pointer;
  box-shadow: 0px 5px 20px -10px #000000;
  transition: background-color 0.2s ease-in;

  .toggle-inner {
    width: 20px;
    height: 15px;
    background-color: white;
    border-radius: 1000px;
    transition: margin-left 0.2s ease-in, background-color 0.2s ease-in;

    &.toggle-active {
      margin-left: 20px;
    }
  }
`;

const Nav = styled.nav`
.hamburger-icon {
  color: ${({ theme }) => theme.text};
  font-size: 1.5rem;
}
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0px 12vw;
  background-color: ${({ theme }) => theme.bg};
  border-bottom: 2px solid ${({ theme }) => theme.text};
  a {
    text-decoration: none;
    cursor: pointer;
  }
  .brand1 {
    .social-name {
      color: ${({ theme }) => theme.text};
      font-family: Andale Mono, monospace;
      margin-top: revert;
    }
    img {
      cursor: pointer;
    }
    .toggle {
      display: none;
    }
  }
  .links {
    display: flex;
    list-style-type: none;
    gap: 2rem;
    margin: initial;
    li {
      .nav-link {
        padding: 0;
      }
      .dropdown-menu {
        width: 15em;
      }
      a {
        color: ${({ theme }) => theme.text};
        font-weight: 600;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #4969e1;
        }
      }

      .active {
        color: #4969e1;
      }
    }
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    button {
      font-size: 10px !important;
    }
  }
  @media screen and (min-width: 260px) and (max-width: 1080px) {
    .brand1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      top: 0;
      .toggle {
        display: block;
      }
    }
    .links {
      display: none;
    }
  }
`;

const ResponsiveNav = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 60%;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.text};
  opacity: ${({ state }) => (state ? "1" : "0")};
  visibility: ${({ state }) => (state ? "visible" : "hidden")};
  transform: translateX(${({ state }) => (state ? "0" : "100%")});
  transition: transform 0.3s ease-in-out;
  z-index: 10;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    li {
      width: 100%;
      margin: 1rem;
      text-align: center;

      a {
        text-decoration: none;
        color: #4969e1;
        font-size: 1.2rem;
        font-weight: 600;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: #fc4958;
        }
      }
    }
  }
`;
