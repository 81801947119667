import React from "react";
import Banner from "./Banner";
import Stack from "./Stack";
import '../../styles/styles.scss';


export default function Home({ theme, toggleTheme }) {
    
    return (
      <>
        <Banner theme={theme}  toggleTheme={toggleTheme}/>
      </>
  );
}

